<template>
  <div class="designer-page">
    <div class="designer-header">
      <el-avatar :size="100" :src="photo || circleUrl"></el-avatar>
      <div class="info-container">
        <!-- <div class="info-person">
          <div class="name">{{ username }}</div>
          <div class="sub-name">
            <span>认证设计师</span>
          </div>
        </div> -->
        <div class="info-about">
          <div class="about-item username">
            <p class="user-name">{{ username }}</p>
            <p>
              <img
                src="../../../assets/images/icon/icon_huiyuanvip.png"
                alt=""
                class="icon_huiyuanvip"
              />
              <span style="vertical-align:middle">认证设计师</span>
            </p>
          </div>
          <div
            class="about-item"
            :class="{ active: current == '/designer/home/concern' }"
            @click="goto('concern')"
          >
            <p>{{ userInfo.followCount }}</p>
            <p>我的关注</p>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="about-item"
            :class="{ active: current == '/designer/home/fans' }"
            @click="goto('fans')"
          >
            <p>{{ userInfo.fansCount }}</p>
            <p>我的粉丝</p>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="about-item"
            :class="{ active: current == '/designer/home/integral' }"
            @click="goto('integral')"
          >
            <p>{{ userInfo.score }}</p>
            <p>我的积分</p>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="about-item"
            :class="{ active: current == '/designer/home/download' }"
            @click="goto('download')"
          >
            <p>{{ userInfo.downloadCount }}</p>
            <p>我的下载</p>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="about-item"
            :class="{ active: current == '/designer/home/likes' }"
            @click="goto('likes')"
          >
            <p>{{ userInfo.myPoint }}</p>
            <p>我的点赞</p>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div
            class="about-item"
            :class="{ active: current == '/designer/home/points' }"
            @click="goto('points')"
          >
            <p>{{ userInfo.pointCount }}</p>
            <p>点赞我的</p>
          </div>
        </div>
      </div>
    </div>
    <div class="designer-components">
      <c-menu></c-menu>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import CMenu from "@/components/CMenu/index";
export default {
  components: {
    CMenu,
  },
  data() {
    return {
      SUCCESS_CODE: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      userInfo: {
        followCount: 0,
        fansCount: 0,
        pointCount: 0,
        score: 0,
        downloadCount: 0,
        myPoint: 0,
      },
      username: "",
      photo: "",
    };
  },
  computed: {
    current() {
      return this.$route.path;
    },
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.username = userInfo.nickname;
    this.admin = userInfo.admin;
    this.photo = userInfo.photo;
    this.getUserScore();
  },
  methods: {
    goto(current) {
      this.$router.push("/designer/home/" + current);
    },
    // 获取用户信息
    getUserScore() {
      this.$http.get("/user-info/load").then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.userInfo = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.user-name{
  max-width: 150px;
}
</style>